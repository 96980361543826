<app-loader [loader]="loader" *ngIf="saving"></app-loader>

<div id="contractFormat" [hidden]="true">
    <div class="contractFormat">

        <span id="contractFolio" style="position: fixed; bottom: 0; left: 0; color: gray;"></span>
        <h1><b>CONTRATO DE PROMESA DE COMPRA</b></h1>

        <br>

        <p>EN LA CIUDAD DE TEPIC, ESTADO DE NAYARIT, A LOS {{(contractData.fechaDato).getDate()}}
            {{convertNumber.numberToText(((contractData.fechaDato).getDate()).toString())}} DÍAS
            DEL MES DE {{monthName.getMonthName(contractData.fechaDato.getMonth()+1)}} DEL AÑO
            {{contractData.fechaDato.getFullYear()}}
            {{convertNumber.numberToText((contractData.fechaDato.getFullYear()).toString())}}
            ANTE LOS TESTIGOS QUE AL CALCE SE IDENTIFICAN Y
            FIRMAN PARA CONSTANCIA;</p>

        <br>

        <h1><b>COMPARECEN:</b></h1>

        <br>

        <p>Por una parte el <b>{{contractData.fk_project=='AJ'?'C. MIGUEL ROMERO CONTRERAS':'ING. CARLOS BETANCOURT RODRIGUEZ'}}</b>, la cual se identifica con su credencial de
            elector expedida por el Instituto Nacional Electoral, con clave de elector <b>{{contractData.fk_project=='AJ'?'RMCNMG69070318H000':'BTRDCR76010414H800'}}</b>, misma
            que cuenta con CURP <b>{{contractData.fk_project=='AJ'?'ROCM690703HNTMNG07':'BERC760104HJCTDR06'}}</b>, a quién en éste documento se le denominará como
            <b>{{contractData.fk_project=='AJ'?'"EL REPRESENTANTE"':'"EL DESARROLLADOR"'}};</b> y por la otra el C. <b>{{(contractData.customerName).toUpperCase()}}
            </b>
            quien se
            identifica con su
            credencial de elector expedida por el Instituto Nacional Electoral, con clave de elector
            <b>{{(contractData.voterKey).toUpperCase()}},</b> misma que cuenta con la CURP
            <b>{{(contractData.CURP).toUpperCase()}},</b> a quién se le designará en éste
            documento como <b>"EL PROMITENTE COMPRADOR";</b> quienes manifiestan que es su deseo celebrar un
            <b><u>CONTRATO
                    DE PROMESA COMPRA,</u></b> el cual están de acuerdo en que quede sujeto a las
            disposiciones que
            establecen las siguientes:
        </p>

        <br>

        <h1><b>DECLARACIONES:</b></h1>

        <br>

        <h2 *ngIf="contractData.fk_project=='AJ'; else DeclarationUs"><b>De "EL REPRESENTANTE":</b></h2>
        <ng-template #DeclarationUs>
          <h2><b>De "EL DESARROLLADOR":</b></h2>
        </ng-template>

        <br>

        <div class="contractStatements" >
            <p *ngIf="contractData.fk_project=='AJ';else DeclarationsUsFirst"><b>PRIMERO. -</b>
              Que cuenta con la capacidad jurídica sobre los bienes inmuebles ubicados en la
              Amplicación La Floresta “Fraccionamiento Mtro. Aaron Joaquin”, con clave catastral
              1-59-20-0339-037 en Tepic, Nayarit. </p>
                <ng-template #DeclarationsUsFirst>
                  <p><b>PRIMERO. -</b> Que es una persona física con actividad empresarial, que
                    cuenta con domicilio fiscal en calle Ecuador #17 colonia San Martín en
                    Tepic, Nayarit, C.P. 63185 y que su registro federal de contribuyentes
                    es BERC760104GX2. </p>
                </ng-template>
        </div>
        <br>
        <div class="contractStatements"  *ngIf="contractData.fk_project!='AJ'">
            <p><b>SEGUNDO. -</b> Que su actividad preponderante es el diseño, planeación,
                dirección, administración, erigir, fraccionar, desarrollar, fomentar e impulsar, toda clase de
                bienes rústicos y urbanización en obras de ingeniería, industrial, comerciales o civiles, sean
                propios, de personas físicas o morales, privadas o públicas.</p>
        </div>

        <br *ngIf="contractData.fk_project!='AJ'">

        <div class="contractStatements">
            <p *ngIf="contractData.projectName == 'Bosco Hogares';"><b>TERCERO. -</b> Que cuenta con la
                capacidad jurídica sobre las Parcelas número 318 Z-3 P-1 y
                308 Z-3 P-1 ubicadas en el ejido de “El pantanal”, en el municipio de Xalisco, Nayarit, sobre
                las cuales habrá de consistir el desarrollo inmobiliario denominado <b>“{{contractData.projectName}}”</b>.</p>

            <ng-container *ngIf="contractData.projectName == 'Altares Habitat'">
                <p><b>TERCERO. -</b> Que cuenta con la capacidad jurídica sobre los bienes
                    inmuebles ubicados en el fraccionamiento denominado “ALTARES
                    HABITAT”, Parcela 145 Z-2 P2/8, en el municipio de Xalisco, Nayarit.</p>
            </ng-container>

            <ng-container *ngIf="contractData.projectName == 'Xalta'">
                <p><b>TERCERO, -</b> Que cuenta con la capacidad jurídica sobre los bienes
                    inmuebles
                    ubicados
                    en
                    el
                    fraccionamiento
                    denominado
                    “Fraccionamiento Xalta”, sobre los predios “EL CERRITO” Y “EL
                    ACHURREÑO”, de la colonia nuevo progreso, Xalisco, Nayarit. </p>
            </ng-container>

            <ng-container *ngIf="contractData.projectName == 'Xalter'">
                <p><b>TERCERO, -</b> Que cuenta con la capacidad jurídica sobre los bienes
                    inmuebles ubicados en el fraccionamiento denominado “Xalter”, en el
                    municipio de Tepic, Nayarit.</p>
            </ng-container>

        </div>

        <br><br>

        <h2><b>De "EL PROMITENTE COMPRADOR":</b></h2>

        <br>

        <div class="contractStatements">
            <p><b>PRIMERO. -</b> Ser una persona física de nacionalidad
                {{(contractData.nationality).toUpperCase()}}, nacida en
                {{(contractData.birthPlace).toUpperCase()}},
                el día {{pipe.transform(contractData.birthDate, 'longDate')}}, de estado civil
                {{contractData.civilStatus}}, de ocupación
                {{contractData.occupation}}, con domicilio
                en {{contractData.address}}, de la colonia {{contractData.localidad}}, con código postal
                {{contractData.postalCode}}, en
                la ciudad de {{contractData.municipality}}, {{contractData.state}} con número telefónico
                {{contractData.phone}} y con correo electrónico
                {{contractData.email}}, con capacidad legal y económica para celebrar este contrato.</p>
        </div>

        <br>

        <div class="contractStatements">
            <p><b>SEGUNDO. -</b> Que sabe y está enterado de la situación jurídica del bien
                inmueble materia de contrato, en cuanto a su régimen de propiedad,
                licencias y permisos, las cuales irán variando durante la vigencia del
                presente contrato.</p>
        </div>

        <br>

        <div class="contractStatements">
            <p><b>TERCERO. -</b> Que se encuentra en pleno uso de sus facultades físicas
                y mentales, y que no ha sido limitada o restringida en forma alguna su
                capacidad de goce y ejercicio, y que cuenta con la solvencia económica
                suficiente para celebrar el presente contrato, libre de vicios, dolo y
                mala fe ajustándose a las cláusulas del mismo.</p>
        </div>

        <br>

        <h1><b>CLAUSULAS:</b></h1>

        <br>

        <div class="contractClauses">
            <p><b><u>PRIMERA. - DEL OBJETO DEL CONTRATO:</u></b> Con estricta sujeción a
                derecho y a las cláusulas del presente instrumento, “EL PROMITENTE
                COMPRADOR” promete y se obliga a comprar a {{contractData.fk_project=='AJ'?'“EL REPRESENTANTE”':'“EL DESARROLLADOR”'}},
                libre de todo gravamen, <span *ngIf="salesInNewContract.length==1; Else MultipleSales">el
                    inmueble que habrá de consistir en el {{contractData.fk_property.substring(5,6) == 'L'? 'lote' :
                    'departamento'}}
                    identificado económicamente con el número {{contractData.lote}}, de la
                    {{contractData.fk_property.substring(2,3) == 'M'? 'manzana' : 'torre'}}
                    {{contractData.manzana}},
                    mismo que se habrá de ubicar en el desarrollo inmobiliario denominado
                    “{{contractData.projectName}}”, el cual habrá de contar con las siguientes medidas y
                    colindancias;</span></p>

            <br>

            <ng-template #MultipleSales>los inmuebles que habrán de consistir en
                <ng-container *ngFor="let u of salesInNewContract; let isLast = last;">el
                    {{u.codeProperty.substring(5,6)
                    == 'L'? 'lote' : 'departamento'}} identificado
                    económicamente con el número {{u.lote}}, de la {{u.codeProperty.substring(2,3) == 'M'? 'manzana' :
                    'torre'}} {{u.manzana}}<span *ngIf="!isLast">,
                    </span></ng-container>
                , mismos que se habrán de ubicar en el desarrollo inmobiliario denominado
                “{{contractData.projectName}}”, los cuales habrán de contar con las siguientes medidas y
                colindancias;
            </ng-template>

            <div class="units" *ngIf="salesInNewContract.length == 1; Else MultipleBorders">
                <p>{{contractData.fk_property.substring(5,6) == 'L'? 'Lote' : 'Departamento'}} marcado
                    económicamente
                    con el número {{contractData.lote}} de la {{contractData.fk_property.substring(2,3) == 'M'?
                    'manzana' : 'torre'}}
                    {{contractData.manzana}}, el cual
                    habrá de contar con una superficie de {{contractData.area}} m2 (metros cuadrados) y que
                    colindará con;
                </p>

                <br>

                <ul>
                    <li><b>Al noreste:</b> {{contractData.northeasBorder}}.</li>
                    <br>
                    <li><b>Al suroeste:</b> {{contractData.southwestBorder}}</li>
                    <br>
                    <li><b>Al noroeste:</b> {{contractData.northwestBorder}}.</li>
                    <br>
                    <li><b>Al sureste:</b> {{contractData.southeastBorder}}.</li>
                </ul>
            </div>

            <ng-template #MultipleBorders>
                <div class="units" *ngFor="let b of salesInNewContract; let i = index;">
                    <p><b><u>El {{ordinalNumber.numberToOrdinal(i + 1)}} de ellos:</u></b>
                        {{b.codeProperty.substring(5,6) == 'L'? 'Lote' :
                        'Departamento'}} Marcado económicamente con el número {{b.lote}}
                        de la {{b.codeProperty.substring(2,3) == 'M'? 'manzana' : 'torre'}}
                        {{b.manzana}}, el cual
                        habrá de contar con una superficie de {{b.area.toFixed(2)}} metros cuadrados y que
                        colindará con;
                    </p>

                    <br>

                    <ul>
                        <li><b>Al noreste:</b> {{b.northeasBorder}}.</li>
                        <br>
                        <li><b>Al suroeste:</b> {{b.southwestBorder}}</li>
                        <br>
                        <li><b>Al noroeste:</b> {{b.northwestBorder}}.</li>
                        <br>
                        <li><b>Al sureste:</b> {{b.southeastBorder}}.</li>
                    </ul>

                    <br>
                </div>
            </ng-template>
        </div>

        <br>

        <div class="contractClauses">
            <p><b>SEGUNDA. - DEL PRECIO PACTADO:</b> El precio total de la operación será de
                <b>{{saleTotalAmount | currency: '$ '}}</b>
                ({{convertNumber.numberToText(saleTotalAmount.toString())}} PESOS 00/100 M.N.), cantidad la cual
                deberá estar cubierta en su totalidad por parte
                de <b>“EL PROMITENTE COMPRADOR”</b> a la firma de la escritura pública en la cual habrá de
                realizarse
                la futura compra venta.
            </p>
        </div>

        <br>

        <div class="contractClauses">
            <p><b>TERCERA. - DEL DEPOSITO EN CALIDAD EN GARANTIA. -</b> Para garantizar el cumplimiento de las
                obligaciones pactadas en este instrumento, <b>“EL PROMITENTE COMPRADOR”</b> entrega a <b>{{contractData.fk_project=='AJ'?'“EL REPRESENTANTE”':'“EL DESARROLLADOR”'}}</b> a la firma del presente contrato, un depósito en garantía por la
                cantidad de <b>{{saleTotalAdvance | currency: '$ '}}</b>
                ({{convertNumber.numberToText(saleTotalAdvance.toString())}} PESOS 00/100 m.n.).</p>
        </div>

        <br>

        <div class="contractClauses">
            <ng-container
                *ngIf="contractData.projectName == 'Bosco Hogares' || contractData.projectName == 'Altares Habitat' || contractData.projectName == 'Xalta' || contractData.projectName == 'Mtro. Aaron Joaquin'; Else XalterSchema">
                <p><b>CUARTA. - DE LA TEMPORALIDAD DEL CONTRATO-.</b> <b>“EL PROMITENTE COMPRADOR”</b> se obliga a
                    realizar el
                    pago por el concepto anterior en favor de <b>{{contractData.fk_project=='AJ'?'“EL REPRESENTANTE”':'“EL DESARROLLADOR”'}}</b> de la cantidad de
                    <b>{{saleTotalMonthly | currency: '$ '}}</b>
                    ({{convertNumber.numberToText(saleTotalMonthly.toString())}} PESOS 00/100 M.N.)
                    durante
                    {{((saleTotalAmount-saleTotalAdvance)/saleTotalMonthly).toFixed(0)}}
                    meses, hasta completar la cantidad pactada en la
                    cláusula SEGUNDA del presente contrato.
                </p>
            </ng-container>

            <ng-template #XalterSchema>
                <p style="word-break: break-word;"><b>CUARTA. - DE LA TEMPORALIDAD DEL CONTRATO-.</b> <b>“EL PROMITENTE
                        COMPRADOR”</b> se obliga a
                    realizar <span id="monthlyPayments"></span> pagos mensuales por la cantidad de
                    <b>{{saleTotalMonthly | currency: '$'}}</b>
                    ({{convertNumber.numberToText(saleTotalMonthly.toString())}} PESOS 00/100 M.N.)
                    por el concepto anterior en favor de <b>{{contractData.fk_project=='AJ'?'“EL REPRESENTANTE”':'“EL DESARROLLADOR”'}}</b>,
                    <span id="annualityPayments"></span> pagos anuales cada doce meses contados a partir de la firma del
                    presente contrato por la cantidad de
                    <b>{{saleTotalAnnuality | currency: '$'}}</b>
                    ({{(saleTotalAnnuality.toString())}} PESOS 00/100 M.N.)
                    <span id="extraPayment"></span>
                    para así completar la cantidad pactada en el presente contrato.
                </p>
            </ng-template>

            <span *ngIf="contractData.projectName == 'Xalta'">
                <br>
                <p><b>“EL PROMITENTE COMPRADOR”</b> se obliga a cubrir, cada doce
                    mensualidades, una mensualidad anticipada adicional a la
                    correspondiente a dicho mes, tal y como se plasma dentro de la corrida
                    financiera se le habrá de proporcionar al mismo. </p>
            </span>
        </div>

        <br>

        <div class="contractClauses">
            <p><b>QUINTA. - DE LA FORMA DE PAGO DE LAS MENSUALIDADES. -</b> <b>“EL PROMITENTE COMPRADOR”</b> se
                obliga a
                realizar el pago, en favor de <b>{{contractData.fk_project=='AJ'?'“EL REPRESENTANTE”':'“EL DESARROLLADOR”'}}</b> mes con mes, durante el periodo
                pactado en
                la cláusula que antecede, directamente en la cuenta bancaria con número de tarjeta <b>{{contractData.fk_project=='AJ'?'4210 0300 5444 7664':'4210 0300 5205 1518'}}</b>
                 o <b>{{contractData.fk_project=='AJ'?"CLABE 030 560 90003049731 6":"cuenta 0228740361401"}}</b> en la institución Bancaria denominada Banco
                BanBajio.</p>

            <br>

            <p>El <b>“PROMITENTE COMPRADOR”</b> acepta, que <b>{{contractData.fk_project=='AJ'?'“EL REPRESENTANTE”':'“EL DESARROLLADOR”'}}</b>
                no reconoce entrega de dinero alguna ya sea parcial o total que <b>“EL PROMITENTE COMPRADOR”</b>
                haga a
                los agentes, comisionistas, gerentes, y de más, toda vez que estos <b><u>NO ESTAN
                        FACULTADOS</u></b> para recibir <u>CANTIDAD ALGUNA DE DINERO EN NOMBRE DE {{contractData.fk_project=='AJ'?'“EL REPRESENTANTE”':'“EL DESARROLLADOR”'}}.</u></p>

            <br>

            <p>A partir del {{pipe.transform(contractData.firstPayDate, 'fullDate')}} como mensualidad número 1
                hasta completar el importe
                del
                contrato.</p>
        </div>

        <br>

        <div class="contractClauses">
            <p><b>SEXTA. - DE LAS CONDICIONES PARA LA PERFECCION DEL CONTRATO DE COMPRA.</b> <b>“EL
                    PROMITENTE
                    COMPRADOR”</b> se obliga a cumplir en tiempo y forma con los pagos estipulados en la
                Cláusula CUARTA
                para liquidar el precio de la operación, en un plazo no mayor a lo estipulado en este
                instrumento.</p>

            <br>

            <p>En caso de que las condiciones antes enunciadas no se cumplan en el plazo, contados a partir de
                la fecha de la firma de este instrumento, <b>“EL PROMITENTE COMPRADOR”</b> expresamente autoriza
                a
                <b>{{contractData.fk_project=='AJ'?'“EL REPRESENTANTE”':'“EL DESARROLLADOR”'}}</b> a disponer del inmueble.
            </p>
        </div>

        <br>

        <div class="contractClauses">
            <p><b>SEPTIMA. DE LOS GASTOS GENERADOS DE LA FUTURA COMPRA.</b> Convienen ambas partes que
                todos
                los gastos, honorarios, impuestos o derechos que se causen por la formalización en escritura
                pública de este contrato de promesa de compra serán por cuenta exclusiva de <b>“EL
                    PROMITENTE COMPRADOR”,</b> con excepción del impuesto sobre la renta que será a cargo de
                <b>{{contractData.fk_project=='AJ'?'“EL REPRESENTANTE”':'“EL DESARROLLADOR”'}}</b>.
            </p>

            <br>

            <p>Cualquier otro gasto adicional será por cuenta exclusiva de <b>“EL PROMITENTE COMPRADOR”,</b> sin
                responsabilidad alguna para <b>{{contractData.fk_project=='AJ'?'“EL REPRESENTANTE”':'“EL DESARROLLADOR”'}}</b>.</p>

            <br>

            <p>Adicionalmente <b>“EL PROMITENTE COMPRADOR”</b> se compromete a pagar, a partir de que tenga la
                posesión del inmueble, el impuesto predial, energía eléctrica, agua, teléfono, cuotas de
                mantenimiento, gastos de conexión a servicios, y demás gastos que se generen por la posesión
                del <b>"inmueble”.</b></p>
        </div>

        <br>

        <div class="contractClauses">
            <p><b>OCTAVA, CONDICIONES SUSPENSIVAS: </b>En términos de los
                artículos 1311 y 1312 del código civil del estado de Nayarit, el presente
                contrato se encuentra sujeto a todas y cada una de las siguientes
                condiciones suspensivas: </p>

            <br>

            <ol style="list-style-type: lower-alpha; padding-left: 40px;">
                <li>Se hayan otorgado y expedido por las autoridades municipales,
                    estatales y federales todas las licencias, las anuencias, los permisos
                    y las autorizaciones necesarias para realizar el “desarrollo
                    inmobiliario” al que se le denominará “{{contractData.projectName}}” en el que se
                    localizará el “lote de terreno” cosa futura. </li>
                <li>Se obtenga la clave catastral y la cuenta predial individual del “lote
                    de terreno”.</li>
                <li>Se obtenga el folio real individual o registral individual del “lote de
                    terreno” ante el registro público de la propiedad y de comercio del
                    estado de Nayarit.</li>
            </ol>

            <br>

            <p>En caso de no actualizarse alguna de las <b>“CONDICIONES
                    SUSPENSIVAS”,</b> el presente contrato se resolverá automáticamente,
                de pleno derecho, sin necesidad de notificación entre las partes, sin
                necesidad de declaración judicial y sin responsabilidad alguna para las
                partes. En dicho caso, <b>{{contractData.fk_project=='AJ'?'“EL REPRESENTANTE”':'“EL DESARROLLADOR”'}}</b> devolverá a <b>“EL
                    PROMITENTE COMPRADOR”</b> la totalidad de los depósitos en garantía
                que éste hubiese entregado, de la misma manera en que <b>“EL
                    PROMITENTE COMPRADOR”</b> las haya entregado. </p>
        </div>

        <br>

        <div class="contractClauses">
            <p><b>NOVENA, DE LOS MOTIVOS DE RESCISION:</b> Será motivo de rescisión;</p>

            <br>

            <ul style="list-style-type: circle; padding-left: 40px;">
                <li>Que <b>"EL PROMITENTE COMPRADOR"</b> incumpla en 2 dos o más pagos de las mensualidades
                    por concepto de depósito en
                    garantía pactadas en la cláusula cuarta. </li>
                <li>Por convenio expreso entre las partes.</li>
            </ul>

            <br>

            <p>Desde este momento <b>“EL PROMITENTE COMPRADOR”</b> acepta que, en caso de rescisión del presente
                contrato, se le penalizara con el 15% quince por ciento sobre el valor de los inmuebles pactada en
                la cláusula SEGUNDA dentro del presente contrato, misma que se le descontará de la suma que
                hubiere entregado a <b>{{contractData.fk_project=='AJ'?'“EL REPRESENTANTE”':'“EL DESARROLLADOR”'}}</b> por concepto de depósito en garantía,
                estipulado en
                las cláusulas Tercera y cuarta del presente contrato y el saldo será devuelto a <b>“EL
                    PROMITENTE
                    COMPRADOR”,</b> sin interés alguno de la misma manera en que el <b>“EL PROMITENTE
                    COMPRADOR”</b> realizo
                sus pagos en base al estado de cuenta correspondiente. </p>

            <br>

            <p>Manifiesta <b>“EL PROMITENTE COMPRADOR”</b> expresamente que en caso de que se actualice uno
                de los
                supuestos que prevé la presente clausula como motivo de rescisión, señala como medio de
                notificación, el número telefónico previamente plasmado en el presente contrato, teniendo 3 días
                naturales para la firma del convenio de rescisión, en caso de no hacerlo se tendrá como
                autorizado tácitamente el convenio en referencia.</p>

            <br>

            <p>Acuerdan <b>LAS PARTES</b> que en caso de que se lleve a cabo la rescisión del presente contrato,
                los
                pagos realizados por concepto de <i>“Nota de crédito no reembolsable”</i> no serán susceptibles
                de
                devolución, siendo únicamente aplicables al monto final como pago.</p>
        </div>

        <br>

        <div class="contractClauses">
            <p><b>DECIMA. - DE LOS GASTOS NO CUANTIFICADOS.</b> Los siguientes conceptos no cuantificados serán
                cubiertos por el <b>PROMITENTE COMPRADOR:</b></p>

            <br>

            <ul style="list-style-type: circle; padding-left: 40px;">
                <li>Avalúos comerciales y catastrales.</li>
                <li>Gastos notariales y sus derivados.</li>
                <li>De más generados no plasmados en el presente contrato.</li>
            </ul>

            <br>

            <p>La cantidad de <b>$200 (DOSCIENTOS PESOS 00/100 M.N.)</b> por concepto de gastos de cobranza en
                caso de
                que <b>"EL PROMITENTE COMPRADOR"</b> incurra en incumplimiento de alguno de los pagos pactados
                en la
                cláusula <b>CUARTA,</b> generando las gestiones de cobranza necesarias para su pago.</p>
        </div>

        <br>

        <div class="contractClauses">
            <p><b>DECIMA PRIMERA. - DEL DOMINIO DEL INMUEBLE -</b> <b>“EL DESARROLLADOR”</b> se reserva
                expresamente el dominio de los inmuebles materia del contrato futuro de compra, hasta que
                haya sido firmada la escritura pública en la que se formalice la futura operación de
                compraventa, por lo que <b>“EL PROMITENTE COMPRADOR”</b> no podrá ceder, ya sea total o
                parcialmente
                los derechos que les concede este contrato.</p>

            <br>

            <p>La escritura pública previamente mencionada se elaborará una vez se haya cumplido con el pago
                total de la cantidad pactada pagado en su totalidad y se encuentre el inmueble urbanizado y con
                los servicios descritos en el presente contrato.</p>
        </div>

        <br>

        <div class="contractClauses">
            <p><b>DECIMA SEGUNDA. - DE LA ENTREGA DEL INMUEBLE. -</b> <b>“EL PROMITENTE COMPRADOR”</b> habrá de
                recibir
                el bien
                inmueble materia del presente contrato, en un plazo de {{(contractData.fk_project=="AJ"&&contractData.manzana=='01')||(contractData.fk_project=="AJ"&&contractData.manzana=='02')?'25 veinticinco':'60 sesenta'}}
                meses contados a partir de la firma del presente contrato, siempre y
                cuando se encuentre a corriente con los pagos estipulados en
                la cláusula <b>CUARTA</b>, y se encuentren cumplidas las condiciones
                suspensivas a las que se sujeta el presente contrato.
                En caso de que, por causas externas no imputables a las partes, no
                sea posible ajustarse al plazo estipulado en la presente clausula, esta
                podrá ser prorrogada por un máximo de 180 ciento ochenta días
                naturales contados a partir de la finalización del plazo señalado en el
                primer párrafo de esta cláusula.
            </p>
        </div>

        <br>

        <div class="contractClauses">
            <p><b>DECIMA TERCERA. - DE LOS SERVICIOS DEL INMUEBLE. -</b> El inmueble materia de compra habrá de
                contar con los siguientes
                servicios:</p>

            <br>

            <ul style="list-style-type: circle; padding-left: 40px;">
                <li>Servicios de agua potable y drenaje sanitario.</li>
                <li>Servicios de electrificación y alumbrado.</li>
                <li>Vialidades de concreto y/o empedrado ahogado, machuelos y banquetas.</li>
            </ul>

            <br>

            <p>Los cuáles serán ejecutados en el proceso del presente contrato, así como autorizaciones y
                permisos correspondientes ante las autoridades correspondientes.</p>
        </div>

        <br>

        <div class="contractClauses">
            <p><b>DECIMA CUARTA. - DEL BENEFICIARIO. -</b> <b>“EL PROMITENTE COMPRADOR”</b> señala a
                ___________________________________ como beneficiario del
                inmueble y del presente contrato, para que, en caso de convenio entre las partes, este continúe
                o rescinda, con perjuicio de lo pactado en la cláusula <b>SEXTA.</b></p>
        </div>

        <br>

        <div class="contractClauses">
            <p><b>DECIMA QUINTA. - FORMALIZACIÓN. -</b> El presente contrato
                representa el acuerdo final total entre <b>“LAS PARTES”,</b> substituye a
                todos sus acuerdos, intenciones o entendimientos previos oral o
                escrito. </p>
        </div>

        <br>

        <div class="contractClauses">
            <p><b>DECIMA SEXTA. - CESIÓN. -</b> El presente contrato, los derechos que deriven de él y sus
                obligaciones no podrán ser transferidos o cedidos sin el consentimiento previo de <b>“LAS
                    PARTES”.</b>
            </p>
        </div>

        <br>

        <div class="contractClauses">
            <p><b>DECIMA SEPTIMA. - DOMICILIOS. -</b> Todas las notificaciones y otras comunicaciones que deban
                hacerse de conformidad con el presente contrato serán por escrito y tendrán efectos cuando sean
                recibidos por el destinatario en el domicilio indicado en el capítulo de declaraciones del
                presente contrato.
            </p>
        </div>

        <br>

        <div class="contractClauses">
            <p><b>DECIMA OCTAVA. - JURISDICCIÓN. -</b> Para todo lo relacionado con la interpretación, ejecución
                y cumplimiento del presente contrato, <b>“LAS PARTES”</b> convienen en someterse a las leyes y
                tribunales competentes del municipio de Tepic del Estado de Nayarit, renunciando a cualquier
                otro fuero que pudiera corresponderles por razón de domicilio presente o futuro.
            </p>
        </div>

        <br>

        <div class="contractClauses">
            <p><b>DECIMA NOVENA. - AUSENCIA DE VICIOS. -</b> Expresan <b>“LAS PARTES”</b> que el presente
                contrato lo
                celebran sin dolo, mala fe, ni enriquecimiento ilícito alguno, por lo que desde estos momentos
                renuncian a promover su nulidad por esas causas y por las demás previstas por el Código Civil
                para el Estado de Nayarit, en vigor, y por sus correlativos de los demás estados de la República
                Mexicana.
            </p>
        </div>

        <br>

        <div class="contractClauses">
            <p><b>VIGESIMA. - TITULOS DE LAS CLAÚSULAS. -</b> <b>“LAS PARTES”</b> manifiestan que los títulos
                establecidos al inicio de cada cláusula son meramente para efectos de identificación, por lo que
                de ninguna manera podrán ser considerados como elemento de restricción o limitación respecto del
                contenido o de los efectos o alcances legales de las mismas.
            </p>
        </div>

        <br>

        <div class="contractClauses"> <!--style="break-before: page;"-->
            <p>
                Este contrato se da y firma en la ciudad de Tepic, Nayarit a los {{today.getDate()}} días del mes de
                {{monthName.getMonthName(today.getMonth()+1).toLowerCase()}} del
                año {{today.getFullYear()}}.
            </p>

            <br>

            <div class="images">
                <ng-container *ngFor="let sales of salesInNewContract;">
                    <img class="sketchMapIMG" [src]="sales.sketchMap" alt="Lotificacion">
                </ng-container>
            </div>

            <br><br><br><br><br>

            <div class="signs">
                <p>
                    {{contractData.customerName.toUpperCase()}}
                    <br>
                    "EL PROMITENTE COMPRADOR"
                </p>

                <p>
                    {{contractData.fk_project=='AJ'?'C. MIGUEL ROMERO CONTRERAS':'ING. CARLOS BETANCOURT RODRIGUEZ'}}
                    <br>
                    {{contractData.fk_project=='AJ'?'"EL REPRESENTANTE"':'"EL DESARROLLADOR"'}}
                </p>
            </div>

            <br><br><br>

            <!-- <div class="signs" style="justify-content: center !important;">
                <p>
                    C.P. MARCO ANTONIO FLORES RIVAS
                    <br>
                    TESTIGO
                </p>
            </div> -->
        </div>
    </div>
</div>

<div class="contractControlContainer">
    <div *ngIf="customersGroups.length==0"
        style="display: flex; flex-direction: column; border-radius: 5px; justify-content: center; align-items: center; padding: 10px; width: 100%; height: 15vh; background-color: var(--bg-color);">
        <h3 style="text-align:center;">No tienes solicitudes pendientes</h3>
        <p>
            <mat-icon style="font-size: 2rem;">sentiment_very_satisfied</mat-icon>
        </p>
    </div>

    <div class="contractControlContainer__pendingCustomers" *ngIf="customersGroups.length>0">
        <span class="contractControlContainer__pendingCustomers__customerCard"
            *ngFor="let customersGroup of customersGroups">
            <div style="width: 10%; text-align: center; font-weight: bold;">
                {{customersGroup.fk_customer}}
            </div>

            <div style="width: 90%;">
                {{customersGroup.customerName}}
            </div>
        </span>
    </div>

    <button id="detailsBtn" class="contractControlContainer__detailBtn saveButton" (click)="showDetails()"
        *ngIf="salesInNewContract.length > 0"><mat-icon>visibility</mat-icon></button>

    <div id="detailsView" class="contractControlContainer__quickCheck" *ngIf="salesInNewContract.length > 0">
        <div class="contractControlContainer__quickCheck__detailsTitle">
            <mat-icon class="m19IconBtn primaryBtn" (click)="hideDetails()">arrow_back_ios</mat-icon>
            <h4>Ventas en el contrato</h4>
        </div>

        <div class="contractControlContainer__quickCheck__salesList">
            <ng-container *ngFor="let s of salesInNewContract; let i = index; let isLast = last;">
                <div class="contractControlContainer__quickCheck__salesList__saleUnit">
                    <span>{{i+1}}. -</span>
                    <div class="saleResume">
                        <span><b>{{s.codeProperty}}</b></span>
                        <span><b>Fecha de venta: </b>{{pipe.transform(s.fechaDato, 'longDate')}}</span>
                        <span><b>Valor total: </b>{{s.amount.toFixed(0) | currency: '$ '}}</span>
                        <span><b>Anticipo: </b>{{s.advancePayment.toFixed(0) | currency: '$ '}}</span>
                        <span><b>Mensualidad: </b>{{s.monthlyPayment.toFixed(0) | currency: '$ '}}</span>
                        <span *ngIf="s.annualityPayment > 0"><b>Anualidad: </b>{{s.annualityPayment.toFixed(0) |
                            currency: '$
                            '}}</span>
                    </div>
                </div>

                <mat-divider *ngIf="!isLast"></mat-divider>
            </ng-container>
        </div>

        <div class="contractControlContainer__quickCheck__saleTotal">
            <span class="totalField">
                <b>Monto total:</b>
                <br>
                {{saleTotalAmount | currency: '$ '}}
            </span>

            <span class="totalField">
                <b>Anticipo total:</b>
                <br>
                {{saleTotalAdvance | currency: '$ '}}
            </span>

            <span class="totalField">
                <b>Mensualidad total:</b>
                <br>
                {{saleTotalMonthly | currency: '$ '}}
            </span>

            <span class="totalField" *ngIf="saleTotalAnnuality > 0">
                <b>Anualidad total:</b>
                <br>
                {{saleTotalAnnuality | currency: '$ '}}
            </span>
        </div>

        <button class="m19Btn" (click)="generateContract()">Generar Contrato</button>
    </div>

    <div class="contractControlContainer__searchBar">
        <div class="ng-autocomplete">
            <ng-autocomplete name="busqueda" placeholder="Ingrese el nombre del cliente" [data]="salesList"
                [searchKeyword]="keyword" (selected)="generateContractOptions($event)" [itemTemplate]="itemTemplate"
                [notFoundTemplate]="notFoundTemplate">
            </ng-autocomplete>
            <ng-template #itemTemplate let-item>
                <a [innerHTML]="item.customerName"></a>
            </ng-template>

            <ng-template #notFoundTemplate let-notFound>
                <div>Ningún cliente coincide con la busqueda</div>
            </ng-template>
        </div>
    </div>

    <div class="contractControlContainer__salesList"
        *ngIf="withoutContract.length > 0 || withContract.length > 0; Else NoContracts">
        <div class="contractControlContainer__salesList__withoutContractSection"
            *ngIf="withoutContract.length > 0; Else NoPending">
            <h4><b>Ventas sin contrato asignado</b></h4>

            <span *ngFor="let p of projectsWithout"
                class="contractControlContainer__salesList__withoutContractSection__projectDivider">
                <h4>{{p}}</h4>

                <mat-divider></mat-divider>

                <br>

                <ng-container *ngFor="let sale of withoutContract; let isLast = last;">
                    <div class="salesCard" *ngIf="sale.name == p">
                        <div class="saleRow">
                            <span><b>{{sale.codeProperty}}</b></span>
                            <span>
                                <mat-icon [id]="sale.idSale+'Btn'" (click)="pushSaleInContract(sale, $event.target);"
                                    class="m19IconBtn primaryBtn">add</mat-icon>
                                <mat-icon [id]="sale.idSale+'BtnDelete'"
                                    (click)="saleDeleted($event.target); deleteSaleInContract(sale);"
                                    class="m19IconBtn primaryBtn" style="display: none;">remove</mat-icon>
                            </span>
                        </div>
                        <div class="saleRow">
                            <span><b>Vendido el</b><br> {{pipe.transform(sale.fechaDato, 'longDate')}}</span>
                            <span><b>Primer pago el</b><br> {{pipe.transform(sale.firstPayDate, 'longDate')}}</span>
                        </div>
                        <div class="saleRow">
                            <span><b>Valor:</b><br> {{sale.amount | currency: '$ '}}</span>
                            <span><b>Anticipo:</b><br> {{sale.advancePayment | currency: '$ '}}</span>
                            <span><b>Mensualidad:</b><br> {{sale.monthlyPayment | currency: '$ '}}</span>
                            <span><b>Anualidad:</b><br> {{sale.annualityPayment | currency: '$'}}</span>
                        </div>
                    </div>

                    <br *ngIf="!isLast && sale.name == p">
                </ng-container>
            </span>
        </div>

        <ng-template #NoPending>
            <div class="contractControlContainer__salesList__noPendingContracts">
                <h4>Sin contratos pendientes</h4>
                <mat-icon>sentiment_very_satisfied</mat-icon>
            </div>
        </ng-template>

        <div class="contractControlContainer__salesList__withContractSection"
            *ngIf="withContract.length > 0; Else NoElaborated">
            <h4><b>Ventas con contrato</b></h4>

            <!-- <span *ngFor="let p of projectsWith"
                class="contractControlContainer__salesList__withContractSection__projectDivider">
                <h4>{{p}}</h4>

                <mat-divider></mat-divider>

                <br> -->

            <ng-container *ngFor="let folio of contractsByFolio;">
                <span class="salesCard">
                    <span style="color: gray;">{{folio}}</span>
                    <ng-container *ngFor="let sales of withContract; let i = index; let isLast = last;">
                        <ng-container *ngIf="sales.idContract === folio">
                            <div (click)="generateContractByFolio(sales.idContract)">
                                <div class="saleRow">
                                    <span><b>El {{ordinalNumber.numberToOrdinal(i + 1)}}
                                            {{sales.codeProperty}}</b></span>
                                </div>
                                <div class="saleRow">
                                    <span><b>Vendido el</b> <br> {{pipe.transform(sales.fechaDato,
                                        'longDate')}}</span>
                                    <span><b>Primer pago el</b> <br> {{pipe.transform(sales.firstPayDate,
                                        'longDate')}}</span>
                                </div>
                                <div class="saleRow">
                                    <span><b>Valor:</b> <br> {{sales.amount | currency: '$ '}}</span>
                                    <span><b>Anticipo:</b> <br> {{sales.advancePayment | currency: '$ '}}</span>
                                    <span><b>Mensualidad:</b> <br> {{sales.monthlyPayment | currency: '$ '}}</span>
                                    <span><b>Anualidad:</b> <br> {{sales.annualityPayment | currency: '$'}}</span>
                                </div>
                            </div>
                        </ng-container>
                        <mat-divider *ngIf="!isLast"></mat-divider>
                    </ng-container>
                </span>
            </ng-container>
            <!-- </span> -->
        </div>

        <ng-template #NoElaborated>
            <div class="contractControlContainer__salesList__noElaboratedContracts">
                <h4>Sin contratos elaborados</h4>
                <mat-icon>sentiment_dissatisfied</mat-icon>
            </div>
        </ng-template>
    </div>

    <ng-template #NoContracts>
        <div class="contractControlContainer__noContracts">
            <h4>Selecciona un cliente con compras para visualizar su estado</h4>
            <mat-icon>check_box</mat-icon>
        </div>
    </ng-template>
</div>
